import React, { useState, useEffect } from 'react'
import { Alert, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { IconCircleCheck, IconDeviceFloppy, IconEdit, IconEye, IconEyeOff, IconLock, IconTrash, IconX } from '@tabler/icons-react'
import PageContainer from '../MainPages/PageContainer'
import Image from '../../Images/singup.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const MyProfile = () => {
    const [loader, setLoader] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue ,reset} = useForm();
    const [ButtonEnable, setButtonEnable] = useState(true);
    const [Deletepopup, setDeletepopup] = useState(false);
    const [Successpopup, setSuccesspopup] = useState(false);
    const [isAdmin,setisAdmin]= useState('')
    const navigate = useNavigate()
    const GetData = async (data) => {
        try {
            const response = await axios.get('/apiV1/get-profile/');
            console.log(response,'respponse');
            
            if (response.status == 200) {
                setisAdmin(response.data.message[0].is_admin)
                setValue('fullname', response.data.message[0].fullname);
                setValue('email', response.data.message[0].email);
                setValue('phone', response.data.message[0].mobile);
            }
        } catch (error) {
            console.error('Error generating test:', error.response ? error.response.data : error.message);
        }
    };
    useEffect(() => {
        GetData()
    }, []);
    
    const Onsubmit = async (data) => {
        setLoader(true);
        try {
            let req = {
                fullname: data.fullname,
                mobile: data.phone
            };
            const response = await axios.patch('/apiV1/update-user-info/', req);
            if (response.status == 200) {
                setButtonEnable(true);
                userInfo()
                setLoader(false);
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            }
        } catch (error) {
            console.error('Error generating test:', error.response ? error.response.data : error.message);
            setLoader(false);
        }
    };  
    const userInfo = async () => {
        try {
          const config = axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("AdminToken")}` }
          const response = await axios.get('/apiV1/user-info/', config);
          console.log(response.data);
          if (response.status == 200) {
            sessionStorage.setItem("user-info", JSON.stringify(response.data))
          }
        } catch (error) {
          console.error('Error generating test:', error.response ? error.response.data : error.message);
        }
      }

const handleDelete=async()=>
{
    try {
        const response = await axios.post('/apiV1/delete-account/');
        if (response.status == 200) {
            setDeletepopup(false)
            setSuccesspopup(true)
        }
    } catch (error) {
        console.error('Error generating test:', error.response ? error.response.data : error.message);
        setLoader(false);
    }
}
    return (
        <>
            <PageContainer title="Batool | My Profile">
                <Card sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px", }} >
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: '100%', }}>
                        <Typography variant="h6" sx={{ fontWeight: 600, mx: 5, mt: 2 }}>
                            My Profile
                        </Typography>
                        <Button
                            sx={{ mr: 5, mt: 2,gap:2,maxHeight:"40px"}}
                            variant="contained"
                            color="primary"
                            onClick={() => setButtonEnable(false)}
                        >
                            Edit <IconEdit/>
                        </Button>
                    </Box>

                    <CardContent sx={{ p: 5, width: "100%"}}>
                        <Grid container spacing={3} sx={{justifyContent:'space-around'}}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <img
                                    src={Image}
                                    alt="Image Description"
                                    style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={6} sx={{justifyContent:"center"}}>
                                    <form onSubmit={handleSubmit(Onsubmit)}>
                                            <Box>
                                                <Box sx={{ mb: 1, mt: 1 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1}}>
                                                        Full Name
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter Full Name"
                                                        disabled={ButtonEnable || loader}
                                                        {...register('fullname', { required: 'Full Name is required',
                                                            pattern: {
                                                             value: /^[a-zA-Z][a-zA-Z\s]*$/,
                                                             message: 'Name must start with an alphabet. Only alphabets and spaces are allowed',
                                                            },
                                                           })}
                                                        error={!!errors.fullname}
                                                        helperText={errors.fullname?.message}
                                                        sx={{ backgroundColor: ButtonEnable ? '#f5f5f5' : 'transparent',maxWidth:500 }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box sx={{ mb: 1, mt: 1 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                                                        Email
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="manager@company.com"
                                                        disabled
                                                        {...register('email')}
                                                        error={!!errors.email}
                                                        helperText={errors.email?.message}
                                                        sx={{ backgroundColor: '#f5f5f5',maxWidth:500 }}
                                                    />
                                                </Box>
                                            </Box>

                                            <Box>
                                                <Box sx={{ mb: 1, mt: 1 }}>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, ml: 1 }}>
                                                        Phone Number
                                                    </Typography>
                                                    <TextField
                                                        fullWidth
                                                        placeholder="Enter Phone Number"
                                                        disabled={ButtonEnable || loader}
                                                        {...register("phone", {
                                                            required: "Phone Number is required",
                                                            pattern: {
                                                              value: /^[1-9]{1}[0-9]{9,11}$/,
                                                              message:
                                                                "Please enter a valid mobile number",
                                                            },
                                                            minLength: {
                                                              value: 10,
                                                              message: "Please enter min 10 numbers ",
                                                            },
                                                            maxLength: {
                                                              value: 12,
                                                              message: "Please enter max 12 numbers ",
                                                            },
                                                          })}
                                                        error={!!errors.phone}
                                                        helperText={errors.phone?.message}
                                                        sx={{ backgroundColor: ButtonEnable ? '#f5f5f5' : 'transparent' ,maxWidth:500 }}
                                                    />
                                                </Box>
                                            </Box>
                                        {!ButtonEnable && (
                                            <Box sx={{ mt: 2, display: 'flex', gap: 2, justifyContent: 'center' }}>
                                                <Button type="submit" variant="contained" color="primary" disabled={loader} sx={{maxHeight:"40px",gap:1}}>
                                                    {loader ? (<CircularProgress size={24} sx={{ color: 'white' }} />) : <>Save <IconDeviceFloppy/></>}
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => {setButtonEnable(true);reset();GetData()}}
                                                    sx={{ backgroundColor: '#fd0100',maxHeight:"40px",gap:1 }}
                                                    disabled={loader}
                                                >
                                                    Cancel <IconX/>
                                                </Button>
                                            </Box>
                                        )}
                                       {isAdmin ? <Button variant='outlined' color="error"sx={{backgroundColor:"transparent",color:"red",gap:1,mt:2}} onClick={()=>setDeletepopup(true)}><IconTrash/>Delete Account</Button>:""}
                                    </form>
                            </Grid>
                        </Grid>
                    </CardContent>

                </Card>
            </PageContainer>
            <Dialog
                open={Deletepopup}
                onClose={() => setDeletepopup(false)}
                aria-labelledby="confirm-delete-dialog-title"
                aria-describedby="confirm-delete-dialog-description"
                PaperProps={{
                    sx: { borderRadius: '16px', padding: '16px' },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <IconButton size="small" onClick={() => setDeletepopup(false)}>
                        <IconX />
                    </IconButton>
                </Box>
                <DialogTitle id="confirm-delete-dialog-title" align="center" sx={{ marginTop: -3 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                width: 60,
                                height: 60,
                                backgroundColor: '#fdecea',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                marginBottom: 2,
                            }}
                        >
                            <IconTrash size={32} color="#e57373" />
                        </Box>
                    </Box>
                    <Typography variant="h6" fontWeight="bold">
                        Confirm Delete
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography id="confirm-delete-dialog-description" align="center">
                        Are you sure you want to delete your Account? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 1 }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{
                            width: 120,
                            borderColor: '#e0e0e0',
                        }}
                        onClick={() => setDeletepopup(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: 120 }}
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={Successpopup}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setSuccesspopup(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: { borderRadius: '16px', padding: '16px' },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <IconButton size="small" onClick={() => setSuccesspopup(false)}>
                        <IconX />
                    </IconButton>
                </Box>
                <DialogTitle id="confirm-delete-dialog-title" align="center" sx={{ marginTop: -3 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                                padding: "16px",
                                gap: 2,
                            }}
                        >
                            <IconCircleCheck size={80} color="#7a5afc" />
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: "600",
                                        fontSize: "2rem",
                                        color: "#7a5afc",
                                        marginBottom: "8px",
                                    }}
                                >
                                    Success!
                                </Typography>
                                <Typography id="confirm-delete-dialog-description" align="center">
                                    Your Account Has been Deleted Successfully
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 1 }}
                >
                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{
                            width: 120,
                            borderColor: '#e0e0e0',
                        }}
                        onClick={() => navigate('/')}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MyProfile
