import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Card, CardContent, Button, Avatar, ToggleButtonGroup, ToggleButton, CircularProgress, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IconDots, IconDotsVertical, IconEye, IconX } from '@tabler/icons-react';
import avatar1 from '../../Images/user-1.jpg'
import avatar2 from '../../Images/user-2.jpg'
import avatar3 from '../../Images/user-3.jpg'
import avatar4 from '../../Images/user-4.jpg'
import Breadcrumb from '../MainPages/Breadcrumb';
import PageContainer from '../MainPages/PageContainer';
const Documents = () => {
    const [projects, setprojects] = useState([]);
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const [loading, setLoading] = useState(true);
    const groupAvatars = [avatar1, avatar2, avatar3, avatar4];
    const navigate = useNavigate();
    const reactcolors = ['#fed466', '#6bc8f3', '#8cd6bb', '#fa9962', '#e875ba']
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [alignment, setAlignment] = useState('Assigned');
    const [filteredProjects, setFilteredProjects] = useState([]);


    const getProjects = async () => {
        try {
            setLoading(true)
            const response = await axios.get('apiV1/projects/')
            if (response.status == 200) {
                console.log(response);
                setprojects(response.data)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    useEffect(() => {
        getProjects()
    }, [])



    return (
        <>
        <PageContainer title="Batool | Documents">
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: "0px 0px 0px 9px", justifyContent: "space-between" ,overflow:"auto"}}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 600,ml:7,fontSize:24,mt:2}}>
                        Documents
                    </Typography>
            <CardContent>
            <Box sx={{ flexGrow: 1, overflowY: "auto", maxHeight: "75vh", p: 2 }}>
                <Grid container spacing={7} sx={{ p: 5 }}>
                    {loading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '250px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        projects.length > 0 ? (
                            projects.map((project, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card
                                        sx={{
                                            width: '100%',
                                            height: '250px',
                                            display: 'flex',
                                            backgroundColor: "#f6f7fb",
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                            transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                                            boxShadow: hoveredIndex === index
                                                ? "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px"
                                                : 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',

                                            '&:hover': {
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                                                backgroundColor: "#fff",
                                                transform: 'translateY(-5px)',
                                            }
                                        }}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >

                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Box sx={{ display: 'flex', gap: 2 }}>
                                                <Box
                                                    sx={{
                                                        width: 60,
                                                        height: 60,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        borderRadius: '4px',
                                                        backgroundColor: reactcolors[index % reactcolors.length],
                                                        color: 'white',
                                                        fontSize: '30px',
                                                        fontWeight: 'bold',
                                                        flexShrink: 0, 
                                                    }}
                                                >
                                                    {project.name.charAt(0).toUpperCase()}
                                                </Box>
                                                <Box sx={{ flexGrow: 1, minWidth: 180, maxWidth: 300 }}>
                                                    <Typography
                                                        variant="h6"
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        {project.name}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', marginLeft: '10px', position: 'relative' }}>
                                                                {groupAvatars.slice(0, Math.min(project.count, 4)).map((avatar, idx) => (
                                                                    <Avatar
                                                                        key={idx}
                                                                        sx={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            marginLeft: '-10px',
                                                                            border: '2px solid white',
                                                                        }}
                                                                        alt={`Avatar ${idx + 1}`}
                                                                        src={avatar}
                                                                    />
                                                                ))}
                                                                {project.count > 4 && (
                                                                    <Avatar
                                                                        sx={{
                                                                            width: 30,
                                                                            height: 30,
                                                                            marginLeft: '-10px',
                                                                            border: '2px solid white',
                                                                            backgroundColor: '#5a1bc2',
                                                                            color: 'white',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            fontSize: '14px',
                                                                        }}
                                                                    >
                                                                        +{project.count - 4}
                                                                    </Avatar>
                                                                )}
                                                            </Box>
                                                </Box>
                                                {/* <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                                                    <IconDots onClick={(event) => handleClick(event, project.id)} />
                                                    <Menu
                                                        sx={{
                                                            '& .MuiPaper-root': {
                                                                boxShadow: '#d9d9d9 0px 1px 2px 1px',
                                                            },
                                                        }}
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => handleEdit(project.id)}>Edit</MenuItem>
                                                        <MenuItem onClick={handleDelete}>Delete</MenuItem>
                                                    </Menu>
                                                </Box> */}

                                            </Box>
                                            <Typography
                                                variant="body2"
                                                mt={2}
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                }}
                                            >
                                                {project.description}
                                            </Typography>
                                        </CardContent>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            sx={{
                                                borderTop: '2px dashed #c0c1c4',
                                                padding: "10px 30px 10px 30px"
                                            }}
                                        >
                                            {project.is_master_input_generated === true ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                    fullWidth
                                                    onClick={() => { navigate(`/generatedfiles?id=${project.id}`) }}
                                                >
                                                    View <IconEye />
                                                </Button>
                                            ) : (
                                                <Tooltip title="Please generate a master input." arrow>
                                                    <span style={{width:"100%"}}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}
                                                            fullWidth
                                                            onClick={() => { alert("You are not allowed to perform this action") }}
                                                            disabled={true}
                                                        >
                                                            View <IconEye />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Box>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
                            >
                                No Documents available
                            </Typography>
                        )
                    )}
                </Grid>
          </Box>  </CardContent>
        </Card>
        </PageContainer>
        </>
    );
};

export default Documents;
