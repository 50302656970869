
import { Button, IconButton, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle,Typography, Avatar, Divider, Alert, TextField, InputAdornment, CircularProgress } from '@mui/material'
import { Box} from '@mui/system';
import {IconEye, IconX,  IconBubbleText, IconClockEdit, IconSend2, IconCircleCheck, IconSearch } from '@tabler/icons-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import PageContainer from '../MainPages/PageContainer';
import { logDOM } from '@testing-library/react';

const Requests = () => {
    const { register, handleSubmit, formState: { errors, }, trigger, getValues, setValue, reset } = useForm({});
    const { register:register2,reset:reset2} = useForm({});
    const [isSubmitting, setisSubmitting] = useState(false)
    const [corrections, setcorrections] = useState(false)
    const [Comments, setComments] = useState(false)
    const [ProjectId, setProjectId] = useState(false)
    const [CommentId, setCommentId] = useState(false)
    const [data, setdata] = useState(false)
    const [error, setError] = useState(false)
    const [Commentsdata, setCommentdata] = useState(false)
    const [CommentHistory, setCommentHistory] = useState([]);
    const [commenttext, setcommenttext] = useState("");
    const [activeTab, setActiveTab] = useState("corrections");
    const [messages,setmessages] = useState([])
    const [CorrectData,setCorrectData]= useState('')
    const [rows,setrows]= useState(10)
    const [totalRows,settotalRows]= useState('')
    const [Page,setPage] = useState(1)
    const [correctionText, setCorrectionText] = useState("");
    const userInfo = JSON.parse(sessionStorage.getItem('user-info'));
    const [BackendError, setBackendError] = useState('')
    const [ProjectIdforReq,setProjectIdforReq]= useState('')
    const [requestStakeid,setrequestStakeid]= useState('')
    const [Approvelid,setApprovelid]= useState('')
    const [IsApprove,setisApprove]= useState('')
    const [updatesuccess,setupdatesuccess]= useState(false)
    const [PopupMessage,setPopupMessage]= useState(false)
    const [searchkey, setsearchkey] = useState('');
    const [searchkey2, setsearchkey2] = useState('');
    const [Dataloading,setDataloading]= useState(false)
    const handleTab = (tabName) => {
        setActiveTab(tabName);
        setValue("searchValue", "");   
        setValue("searchValue2", ""); 
    };
    const params = new URLSearchParams(window.location.search);
        const requestId = params.get("request") ? params.get("request") : "";
        const projectid = params.get("id") ? params.get("id") : "";
     useEffect(() => {
            // const previousreqId = localStorage.getItem("lastreqId");
            if (requestId !== "") {
                // localStorage.setItem("lastreqId", requestId);
                setcorrections(true);
                getRequest({ project: projectid, id: requestId });
            }
            // return () => {
            //     localStorage.removeItem("lastreqId");
            // };
        }, [requestId]);
    const columns = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Project Name</Typography>,
            selector: (row) => row.meta, cell: row => row.project_name,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Stake Holder</Typography>,
            selector: (row) => row.meta, cell: row => row.stakeholder_email,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
            selector: (row) => row.meta, cell: row => row.stakeholder_designation,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Status</Typography>,
            selector: (row) => row.meta,cell: (row) =>row.approval_status? row.approval_status.charAt(0).toUpperCase() + row.approval_status.slice(1): '',
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Corrections</Typography>,
            selector: (row) => row.meta,
            cell: row => (
                <>
                    <div
                        className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
                        role="button"
                        onClick={() =>{ setcorrections(true);getRequest(row);setProjectIdforReq(row.project);setrequestStakeid(row.stakeholder_id);setApprovelid(row.id);setisApprove(row.can_approve)}}
                        style={{ marginRight: '10px' }} 
                    >
                        <IconEye color={"#182286"} />
                    </div>
                </>
            ),
        },
        

    ];
    const columns1 = [
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Date</Typography>,
            selector: (row) => row.meta, cell: row => row.date,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Project Name</Typography>,
            selector: (row) => row.meta, cell: row => row.project_name,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Stake Holder</Typography>,
            selector: (row) => row.meta, cell: row => row.stakeholder,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Designation</Typography>,
            selector: (row) => row.meta, cell: row => row.designation,
            sortable: true,
        },
        {
            name: <Typography variant="subtitle2" fontWeight={600}>Comments</Typography>,
            selector: (row) => row.meta,
            cell: row => (
                <>
                    <div
                        className="DarkBlue4_Color font_weight_300 font_14 cursor-pointer"
                        role="button"
                        onClick={() => { setComments(true); getCommentHistory(row); setProjectId(row.proj_id); setCommentId(row.com_id) }}
                        style={{ marginRight: '10px' }}
                    >
                        <IconEye color={"#182286"} />
                    </div>
                </>
            ),
        }

    ];
    const getComments = async (size=rows,page=Page,search=searchkey2) => {
        try {
            setDataloading(true)
            const response = await axios.get(`/apiV1/project-comments/?limit=${size}&offset=${(page-1)*10}${search ? `&search=${search}` : ""}`);
            setCommentdata(response.data.results)
            setDataloading(false)
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)
            setDataloading(false)

        }
    };
    const getCommentHistory = async (data) => {
        try {
            const response = await axios.get(`/apiV1/view-comment/?project_id=${data.proj_id}&commented_on_id=${data.com_id}`);
            if (response.status == 200) {
                setCommentHistory(response.data)
            }

        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)

        }
    };
    const getUser = async (size=rows,page=Page,search=searchkey) => {
        try {
            setDataloading(true)
            const response = await axios.get(`/apiV1/raise-request/?limit=${size}&offset=${(page-1)*10}${search ? `&search=${search}` : ""}`);
            setdata(response.data.results)
            settotalRows(response.data.count)
            setDataloading(false)
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
            setisSubmitting(false)
            setDataloading(false)

        }
    };
    useEffect(() => {
        if (activeTab === 'corrections') {
            getUser();
        } else {
            getComments();
        }
    }, [activeTab]);
    
    const getRequest = async (data) => {
        console.log(data);
        try {
            const response = await axios.get(`/apiV1/raise-request/project-requests/?project_id=${data.project}&input_id=${data.id}`);
            if (response.status == 200) {
                setCorrectData(response.data[0])
                setrequestStakeid(response.data[0].stakeholder_id)
                console.log(response.data[0],'stakeholder id');
                setisApprove(response.data[0].can_approve)
                setApprovelid(response.data[0].id)
                const allComments = response.data.flatMap(item => item.comments);
                setmessages(allComments);
            }
        } catch (error) {
            console.error("Error generating project:", error.response ? error.response.data : error.message);
        }
    };
    const handlePostComment = async () => {
        if (commenttext.trim() === "") {
            setError(true);
            return;
        }

        const requestData = {
            commented_on: CommentId,
            comment: commenttext,
            project: ProjectId
        };

        try {
            const response = await axios.post("/apiV1/comments/", requestData);
            console.log(response, 'responseresponse');
            if (response.status === 201) {
                setcommenttext('')
                setError(false);
                getUser()
                setComments(false)
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            // setBackendError(error.response.data.detail);

        }
    };
    const handleCommentChange = (event) => {
        setcommenttext(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const handleTextChange = (event) => {
        setCorrectionText(event.target.value);
        if (event.target.value.trim() !== "") {
            setError(false);
        }
    };
    const handleApproval = async (data) => {
        const requestData = {
            approval_status: data
        };

        try {
            const response = await axios.put(`/apiV1/raise-request/${Approvelid}/update-approval/`, requestData);
            console.log(response, 'responseresponse');
            if (response.status === 200) {
                setcorrections(false)
                setupdatesuccess(true)
                const popupMessage = data === "approved" ? "Approved Successfully" : "Rejected Successfully";
                setPopupMessage(popupMessage);
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const handleSend = async () => {
        if (correctionText.trim() === "") {
            setError(true);
            return;
        }
        const requestData = {
            input_id: requestStakeid,
            approval_status: "pending",
            comment: correctionText,
            project_id: ProjectIdforReq ?ProjectIdforReq :projectid
        };
        try {
            const response = await axios.post("/apiV1/raise-request/", requestData);
            if (response.status === 200) {
                setError(false);
                setcorrections(false)
                setCorrectionText('')
            }
        } catch (error) {
            console.error("Error raising request:", error.response ? error.response.data : error.message);
            setBackendError(error.response.data.detail);

        }
    };
    const hanlderowsChange =(data)=>
    {
        setrows(data)
        getUser(data,Page)
    }
    const handleClosePopup = (setDialogState, paramToRemove) => {
        setError(false)
        const params = new URLSearchParams(window.location.search);
        if (paramToRemove) {
            params.delete(paramToRemove); 
        }
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState(null, "", newUrl); 
        setDialogState(false); 
    };
    const paginationComponentOptions = {
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
      };
      const handlePageChange = (event) => {
        console.log(event,'evene');
        
            setPage(event)
            getUser(rows,event)
      };
      const onSubmits2 = async (data) => {
        setsearchkey(data)
        getUser(rows,Page,data)
    }
    const onSubmits3 = async (data) => {
        setsearchkey2(data)
        getComments(rows,Page,data)
    }
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        if (id && params.toString() !== `id=${id}`) {
            const newUrl = `${window.location.pathname}?id=${id}`;
            window.history.replaceState(null, "", newUrl); 
        }
    }, []);
    
    return (
        <>
        <PageContainer title="Batool | Requests">
            <Card sx={{ display: 'flex', flexDirection: 'column', p: 5, margin: "0px 0px 0px 9px", position: 'relative' }}>
                <CardContent sx={{ alignContent: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box>
                            <Button
                                onClick={() => handleTab('corrections')}
                                variant="contained"
                                sx={{
                                    backgroundColor: activeTab === 'corrections' ? '#5a1bc2' : '#fff',
                                    color: activeTab === 'corrections' ? '#fff' : '#000',gap:1
                                }}
                            >
                                Corrections <IconClockEdit sx={{ ml: 2 }} />
                            </Button>
                        </Box>
                        <Box sx={{ gap: 2 }}>
                            <Button
                                onClick={() => handleTab('comments')}
                                variant="contained"
                                sx={{
                                    backgroundColor: activeTab === 'comments' ? '#5a1bc2' : '#fff',
                                    color: activeTab === 'comments' ? '#fff' : '#000', gap: 1
                                }}
                            >
                                Comments <IconBubbleText sx={{ ml: 2 }} />
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        {activeTab == "corrections" ?
                            <>
                             <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                                        <TextField
                                            sx={{minWidth: '260px',maxHeight:"2.5rem" }}
                                            id="outlined-basic"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconSearch size={'16'} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            {...register("searchValue")} onKeyUp={(e) => { onSubmits2(e.target.value); }}
                                            placeholder="Search "
                                            variant="outlined" />
                                    </Box> 
                             <DataTable
                                columns={columns}
                                data={Dataloading ? [] : data}
                                fixedHeader
                                highlightOnHover
                                pagination
                                persistTableHead
                                paginationServer
                                paginationPerPage={rows}
                                paginationTotalRows={totalRows}
                                fixedHeaderScrollHeight="500px"
                                onChangeRowsPerPage={hanlderowsChange}
                                paginationComponentOptions={paginationComponentOptions}
                                onChangePage={handlePageChange}
                                progressPending={Dataloading} 
                                progressComponent={
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                                        <CircularProgress />
                                    </div>
                                }
                            />
                        </>:
                        <>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                                        <TextField
                                            sx={{minWidth: '260px',maxHeight:"2.5rem" }}
                                            id="outlined-basic"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconSearch size={'16'} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            size="small"
                                            {...register2("searchValue2")} onKeyUp={(e) => { onSubmits3(e.target.value); }}
                                            placeholder="Search "
                                            variant="outlined" />
                                    </Box>
                            <DataTable
                                columns={columns1}
                                data={Dataloading ? [] : Commentsdata}
                                fixedHeader
                                highlightOnHover
                                persistTableHead
                                paginationServer
                                fixedHeaderScrollHeight='550px'
                                onChangeRowsPerPage={hanlderowsChange}
                                paginationComponentOptions={paginationComponentOptions}
                                onChangePage={handlePageChange}
                                progressPending={Dataloading} 
                                progressComponent={
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                                        <CircularProgress />
                                    </div>
                                }
                            />
                            </>
                        }
                    </Box>

                </CardContent>
            </Card>

            <Dialog
                open={corrections}
                onClose={() => handleClosePopup(setcorrections, "request")} 
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>
                    Request For Correction
                    <IconButton
                        onClick={() => handleClosePopup(setcorrections, "request")} 
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                    {messages.map((message) => (<>
                                <Box
                                    key={message.id}
                                    display="flex"
                                    justifyContent={userInfo.email === message.texted_by ? "flex-end" : "flex-start"}
                                    alignItems="center"
                                    gap={2}
                                >
                                    {userInfo.email !== message.texted_by && (
                                        <Avatar sx={{ backgroundColor: "blue" }}>
                                            {message.texted_by.charAt(0).toUpperCase()}
                                        </Avatar>
                                    )}
                                    <Box
                                        sx={{
                                            backgroundColor: userInfo.email === message.texted_by ? "#e3f2fd" : "#f0f0f0",
                                            color: userInfo.email === message.texted_by ? "#0d47a1" : "#000",
                                            borderRadius: 2,
                                            padding: "8px 16px",
                                            maxWidth: "70%",
                                        }}
                                    >
                                        <Typography>{message.text_message}</Typography>
                                    </Box>
                                    {userInfo.email === message.texted_by && (
                                        <Avatar>{message.texted_by.charAt(0).toUpperCase()}</Avatar>
                                    )}
                                </Box>
                                    {userInfo.email !== message.texted_by && (
                                        <Typography sx={{ fontSize: "12px" }}>From: {message.texted_by}</Typography>
                                    )}</>
                            ))}

                    </Box>
                </DialogContent>
                {CorrectData.approval_status === "pending" ?<DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={correctionText}
                            onChange={handleTextChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={handleSend}
                        >
                            <IconSend2 />
                        </Button>
                    </div>

                        {error ?<Alert severity='error'>Please Enter Comment</Alert>:""}
                    {IsApprove === true ? <> <Divider />
                            <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                                <Button variant="contained" sx={{ backgroundColor: '#6dc308' }} onClick={() => handleApproval('approved')} disabled={correctionText !==""}>
                                    Approve
                                </Button>
                                <Button variant="contained" color="error" sx={{ backgroundColor: '#fe0000' }} onClick={() => handleApproval('rejected')} disabled={correctionText !==""}>
                                    Reject
                                </Button>
                            </Box></> : ""}
                </DialogActions>:""}
            </Dialog>






            <Dialog open={Comments} onClose={() => setComments(false)} fullWidth maxWidth="sm">
                <DialogTitle>
                    Comments
                    <IconButton
                        onClick={() =>{ setComments(false);setError(false)}}
                        style={{ position: "absolute", right: 8, top: 8 }}
                    >
                        <IconX />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box display="flex" flexDirection="column" gap={2} p={2}>
                    {CommentHistory.map((message) => (
                                <Box key={message.id} mb={2}>
                                    <Box
                                        display="flex"
                                        justifyContent={userInfo.email === message.commented_by_email ? "flex-end" : "flex-start"}
                                        alignItems="center"
                                        gap={2}
                                    >
                                        {userInfo.email !== message.commented_by_email && (
                                            <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                                {message.commented_by_email.charAt(0).toUpperCase()}
                                            </Avatar>
                                        )}
                                        <Box
                                            sx={{
                                                backgroundColor: userInfo.email === message.commented_by_email ? "#e3f2fd" : "#f0f0f0",
                                                color: userInfo.email === message.commented_by_email ? "#0d47a1" : "#000",
                                                borderRadius: 2,
                                                padding: "8px 16px",
                                                maxWidth: "70%",
                                                wordWrap: "break-word",
                                            }}
                                        >
                                            <Typography>{message.comment}</Typography>
                                        </Box>
                                        {userInfo.email === message.commented_by_email && (
                                            <Avatar sx={{ bgcolor: "#795be8", color: "#fff" }}>
                                                {message.commented_by_email.charAt(0).toUpperCase()}
                                            </Avatar>
                                        )}
                                    </Box>
                                    {userInfo.email !== message.commented_by_email && (
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "#888",
                                                textAlign: "left",
                                                mt: 0.5,
                                            }}
                                        >
                                            Commented By: {message.commented_by_email}
                                        </Typography>
                                    )}
                                </Box>
                            ))}

                    </Box>
                </DialogContent>
                <DialogActions sx={{ flexDirection: "column", alignItems: "stretch", gap: 2, p: 3 }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 600, ml: 1 }}
                    >
                        Comment
                    </Typography>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <textarea
                            placeholder="Add Your Comment Here......"
                            rows={10}
                            value={commenttext}
                            onChange={handleCommentChange}
                            style={{
                                width: '100%',
                                height: '150px',
                                borderRadius: '12px',
                                padding: '10px',
                                fontSize: '16px',
                                border: '1px solid #ccc',
                                resize: 'vertical',
                                paddingRight: '50px',
                            }}
                        />
                        {/* <Button
                            style={{
                                position: 'absolute',
                                right: '10px',
                                bottom: '10px',
                                height: '30px',
                                borderRadius: '8px',
                                backgroundColor: '#7a5af9',
                                color: '#fff',
                                border: 'none',
                                cursor: 'pointer',
                                padding: '0 10px',
                            }}
                            onClick={() => handlePostComment()}
                        >
                           <IconSend2/>
                        </Button> */}
                    </div>

                    {error ?<Alert severity='error'>Please Enter Comment</Alert>:""}
                    <Divider />
                    <Box display="flex" justifyContent="end" sx={{ gap: 2 }}>
                        <Button variant="contained" onClick={() => handlePostComment()}>
                            Send
                        </Button>

                    </Box>
                </DialogActions>
            </Dialog>
            </PageContainer>
            <Dialog
                open={updatesuccess}
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        setupdatesuccess(false);
                    }
                }}
                aria-labelledby="success-dialog-title"
                aria-describedby="success-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogContent>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            padding: "16px",
                            gap: 2,
                        }}
                    >
                        <IconCircleCheck size={80} color="#7a5afc" />
                        <Box>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "600",
                                    fontSize: "2rem",
                                    color: "#7a5afc",
                                    marginBottom: "8px",
                                }}
                            >
                                Success!
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "1rem",
                                    color: "textSecondary",
                                }}
                            >
                                {PopupMessage}
                            </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        size="small"
                        sx={{
                            backgroundColor: "#7a5afc",
                            color: "white",
                            "&:hover": {
                                backgroundColor: "#5d1ac0",
                            },
                            textTransform: "none",
                        }}
                        onClick={() => {setupdatesuccess(false) ;getUser()}}>
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Requests
